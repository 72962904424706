import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../../Common/Alert";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";

import useAlertFromLocation from "../../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../../hooks/useDeleteData";
import ReactDataTable from "../../../../Common/ReactDataTable";

const List = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [inputs, setInputs] = useState({});
  const [region, setRegion] = useState({});
  const [area, setArea] = useState({});
  const [base, setBase] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [routeRes, regionRes] = await Promise.all([Http.get("/route"), Http.get("/region")]);
      setReceiveData(routeRes.data);
      setRegion(regionRes.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Area Load Region Change Wise
  const areaRegionWise = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    Http.get("/region-wise-area/" + value).then((res) => {
      setArea(res.data);
    });

    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Base Load Region Change Wise
  const baseRegionWise = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    Http.get("/area-wise-base/" + value).then((res) => {
      setBase(res.data);
    });

    setInputs((values) => ({ ...values, [name]: value }));
  };

  //  Set Input Value with state
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // to delete data call
  const deleteData = useDeleteData(fetchData);
  // to show alert message
  const alert = useAlertFromLocation();

  // Form Submit
  const formSubmit = () => {
    Http.post("/route-filter", inputs).then((res) => {
      setReceiveData(res.data);
    });
  };

  // columns for the datatable
  const cols = [
    { field: "name", header: "Name" },
    {
      field: (row) => (row.region ? row.region.name : ""),
      header: "Region",
    },
    {
      field: (row) => (row.area ? row.area.name : ""),
      header: "Area",
    },
    {
      field: (row) => (row.base ? row.base.name : ""),
      header: "Base",
    },
    { field: "started_from", header: "Started From" },
    { field: "remarks", header: "Remarks" },
    { field: "status", header: "Status" },
  ];

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Route</h5>
            <Link to="/createroute" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Route
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-sm-40">
              <div className="row">
                <div className="col-lg">
                  <select className="form-control" name="work_place_region_id" onChange={areaRegionWise}>
                    <option value="">Select Region</option>
                    {Array.from(region).map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg">
                  <select className="form-control" name="work_place_area_id" onChange={baseRegionWise}>
                    <option value="">Select Area</option>
                    {Array.from(area).map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg">
                  <select className="form-control" name="work_place_base_id" onChange={handleChange}>
                    <option value="">Select Base</option>
                    {Array.from(base).map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg">
                  <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                    <i className="fa fa-send mg-r-10"></i> Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="am-pagebody">
            <div className="card pd-sm-40">
              <div className="row">
                <ReactDataTable cols={cols} receiveData={receiveData} isLoading={loading} actionTemplate={actionTemplate} deleteData={deleteData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData, deleteData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: "/editroute/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(rowData.id, "deleteRoute", "Route")} title="Delete">
        <i className="fa fa-trash"></i>
      </button>
    </div>
  );
};

export default List;
