import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [staff, setStaff] = useState({});
  const [error, setError] = useState({});
  const [grade, setGrade] = useState({});
  const [designation, setDesignation] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Http.get("/grade");
        setGrade(res.data);
        const resDes = await Http.get("/designation");
        setDesignation(resDes.data);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const staffChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...staff]; // Defined Experience State All Information into List Variable
    inputInfo[index][name] = value;

    setStaff(inputInfo);
  };
  const typeChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    const staffArray = [];
    setLoading(true); // Set loading to true when fetch begins

    try {
      let response;
      switch (value) {
        case "others":
          response = await Http.get("/staff?status=active");
          break;
        case "increment":
          response = await Http.get("/staff?status=active&apa=increment");
          break;
        case "promotion":
          response = await Http.get("/staff?status=active&apa=promotion");
          break;
        case "upgradation":
          response = await Http.get("/staff?status=active&apa=upgradation");
          break;
        default:
          setLoading(false);
          return; // Early return if value does not match
      }

      response.data.forEach((d, index) => {
        if (!d.show && value !== "others") return; // Skip if show is not true and type is not 'others'
        staffArray.push({
          id: d.id,
          card_no: d.card_no,
          name: d.name,
          designation: d.designation.name,
          department: d.department.name,
          gross: d.salary_structure_latest.gross,
          effective_date: "",
          basic: "",
          other: "",
          apa_name: "",
          designation_id: "",
        });
      });
      setStaff(staffArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false); // Set loading to false when fetch completes
  };

  // useEffect(() => {
  //   Http.get("/grade").then((res) => {
  //     // Get All Designation Info
  //     setGrade(res.data); // Set Designation State
  //   });
  //   Http.get("/designation").then((res) => {
  //     // Get All Designation Info
  //     setDesignation(res.data); // Set Designation State
  //   });
  //   DatatableFunc();
  // }, []);
  const formSubmit = () => {
    inputs.staff = staff;
    Http.post("/apa", inputs)
      .then((res) => {
        navigate("/apa", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create APA</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
              New Salary Increment Scale
            </h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label>
                  Type: <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="type"
                  onChange={typeChange}
                >
                  <option value="">Select Type</option>
                  <option value="others">Others (Tk.)</option>
                  <option value="increment">Annual Increment (Qty.)</option>
                  <option value="promotion">Promotion</option>
                  <option value="upgradation">Upgradation</option>
                </select>
              </div>
              <div className="col-lg">
                <label>
                  Remarks <i className="fa fa-star required"></i>
                </label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks || ""}
                  onChange={handleChange}
                  placeholder="Remarks"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2">
                  <table className="table container _datatable">
                    <thead>
                      <tr>
                        <th scope="col">Sl</th>
                        <th scope="col">Card No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Department</th>
                        <th scope="col" style={{ textAlign: "right" }}>
                          Current Salary
                        </th>
                        <th scope="col">Effective Date</th>
                        {inputs.type != "promotion" &&
                        inputs.type != "upgradation" ? (
                          <th scope="col">Basic</th>
                        ) : (
                          ""
                        )}
                        {inputs.type != "promotion" &&
                        inputs.type != "upgradation" ? (
                          <th scope="col">Other</th>
                        ) : (
                          ""
                        )}
                        {inputs.type == "promotion" ||
                        inputs.type == "upgradation" ? (
                          <th scope="col">Grade</th>
                        ) : (
                          ""
                        )}
                        {inputs.type == "promotion" ||
                        inputs.type == "upgradation" ? (
                          <th scope="col">Designation</th>
                        ) : (
                          ""
                        )}
                        {inputs.type == "others" ? (
                          <th scope="col">Name</th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    {loading ? (
                      <tr>
                        <td colSpan="100%" style={{ textAlign: "center" }}>
                          <ProgressSpinner />
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tbody>
                          {Array.from(staff).map((item, index) => (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>{item.card_no}</td>
                              <td>{item.name}</td>
                              <td>{item.designation}</td>
                              <td>{item.department}</td>
                              <td style={{ textAlign: "right" }}>
                                {parseFloat(item.gross).toFixed(2)}
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="effective_date"
                                  onChange={(e) => staffChange(e, index)}
                                  value={item.effective_date}
                                />
                              </td>
                              {inputs.type != "promotion" &&
                              inputs.type != "upgradation" ? (
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="basic"
                                    onChange={(e) => staffChange(e, index)}
                                    value={item.basic}
                                  />
                                </td>
                              ) : (
                                ""
                              )}
                              {inputs.type != "promotion" &&
                              inputs.type != "upgradation" ? (
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="other"
                                    onChange={(e) => staffChange(e, index)}
                                    value={item.other}
                                  />
                                </td>
                              ) : (
                                ""
                              )}
                              {inputs.type == "promotion" ||
                              inputs.type == "upgradation" ? (
                                <td>
                                  <select
                                    className="form-control"
                                    name="grade_id"
                                    onChange={(e) => staffChange(e, index)}
                                  >
                                    <option value="">Select Grade</option>
                                    {Array.from(grade).map((item, index) => (
                                      <option value={item.id} key={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              ) : (
                                ""
                              )}
                              {inputs.type == "promotion" ||
                              inputs.type == "upgradation" ? (
                                <td>
                                  <select
                                    className="form-control"
                                    name="designation_id"
                                    onChange={(e) => staffChange(e, index)}
                                  >
                                    <option value="">Select Designation</option>
                                    {Array.from(designation).map(
                                      (item, index) => (
                                        <option value={item.id} key={item.id}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </td>
                              ) : (
                                ""
                              )}
                              {inputs.type == "others" ? (
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="apa_name"
                                    onChange={(e) => staffChange(e, index)}
                                    value={item.apa_name}
                                  />
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Create
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
