import React from "react";
import { Link } from "react-router-dom";

const Workplace = ({ onMenuClick, active }) => {
  return (
    <>
      <li className="nav-item">
        <Link
          to
          className={`nav-link with-sub ${
            active === "/region" || active === "/area" || active === "/base" || active === "/route" ? "active show-sub" : ""
          }`}
        >
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Workplace</span>
        </Link>
        <ul className="nav-sub">
          <li className="nav-item">
            <Link to="/region" className={`nav-link ${active === "/region" ? "active" : ""}`} onClick={() => onMenuClick("/region")}>
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Region
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/area" className={`nav-link ${active === "/area" ? "active" : ""}`} onClick={() => onMenuClick("/area")}>
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Area
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/base" className={`nav-link ${active === "/base" ? "active" : ""}`} onClick={() => onMenuClick("/base")}>
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Base
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/route" className={`nav-link ${active === "/route" ? "active" : ""}`} onClick={() => onMenuClick("/route")}>
              <i className="fa fa-clone" aria-hidden="true"></i>
              &nbsp;&nbsp; Route
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
};

export default Workplace;
