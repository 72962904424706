import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  // const fetchData = () => {
  //   Http.get("/special-late-facility").then((res) => {
  //     setReceiveData(res.data);
  //   });
  // };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/special-late-facility");
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // to delete data
  const deleteData = useDeleteData(fetchData);

  // to show alert message
  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  // to show alert message from location state
  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
    console.log("location: ", location);
  }, [location]);

  // columns for the datatable
  const cols = [
    { field: "staff.name", header: "Name" },
    { field: "staff.card_no", header: "Card No" },
    { field: "year", header: "Year" },
    { field: "staff.designation.name", header: "Designation" },
    { field: "staff.department.name", header: "Department" },
    { field: "staff.company_location.name", header: "Location" },
    { field: "staff.email", header: "Email" },
    { field: "staff.mobile", header: "Mobile" },
    { field: "staff.type", header: "Type" },
    { field: "staff.joining_date", header: "Joining Date" },
    { field: "staff.blood_group", header: "Blood Group" },
    { field: "staff.date_of_birth", header: "Date Of Birth" },
    { field: "staff.type", header: "Type" },
    { field: "staff.status", header: "Status" },
  ];
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
  //         setReceiveData(res.data);
  //         Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
  //       });
  //     }
  //   });
  // };

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Special Late Facility</h5>
            <Link to="/create-special-late-facility" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>New
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              <div className="row">
                <ReactDataTable cols={cols} receiveData={receiveData} isLoading={loading} actionTemplate={actionTemplate} deleteData={deleteData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData, deleteData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: `/edit-special-late-facility/${rowData.id}`,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      <button
        className="btn btn-danger btn-sm ms-1"
        onClick={() => deleteData(rowData.id, "special-late-facility", "Special Late Facility")}
        title="Delete"
      >
        <i className="fa fa-trash"></i>
      </button>
    </div>
  );
};

export default List;
