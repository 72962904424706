import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const Staff = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/leave-deduction");
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // to delete data call
  const deleteData = useDeleteData(fetchData);
  // to show alert message
  const alert = useAlertFromLocation();

  const cols = [
    { field: (row) => row.staff?.card_no, header: "Card No" },
    {
      field: (row) => (
        <Link to={{ pathname: "/profile/" + row.staff?.id }}>
          {row.staff?.name}
        </Link>
      ),
      header: "Name",
    },
    {
      field: (row) =>
        row.staff?.designation ? row.staff?.designation.name : "",
      header: "Designation",
    },
    {
      field: (row) => (row.staff?.department ? row.staff?.department.name : ""),
      header: "Department",
    },
    {
      field: (row) =>
        row.staff?.company_location ? row.staff?.company_location.name : "",
      header: "Location",
    },
    { field: "year", header: "Year" },
    { field: "casual", header: "Casual" },
    { field: "sick", header: "Sick" },
    { field: "earn", header: "Earn" },
    { field: "others", header: "Others" },
    { field: "remarks", header: "Remarks" },
  ];

  // const deleteData = (id, deleteApiUrl, showMsg) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
  //         setReceiveData(res.data);
  //         Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
  //       });
  //     }
  //   });
  // };

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Leave Deduction</h5>
            <Link to="/create-leave-deduction" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i> New
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <ReactDataTable
                  cols={cols}
                  receiveData={receiveData}
                  isLoading={loading}
                  actionTemplate={actionTemplate}
                  deleteData={deleteData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData, deleteData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: "/edit-leave-deduction/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      <button
        className="btn btn-danger btn-sm ms-1"
        onClick={() =>
          deleteData(rowData.id, "leave-deduction", "Leave Deduction")
        }
        title="Delete"
      >
        <i className="fa fa-trash"></i>
      </button>
    </div>
  );
};

export default Staff;
