import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";

const Edit = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();

  const [asset, setAsset] = useState({});
  const [staff, setStaff] = useState({});
  const [location, setLocation] = useState({});
  const [factory, setFactory] = useState({});
  const [department, setDepartment] = useState({});

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  // const fetchData = () => {
  //   Http.get("/asset-assign/" + id + "/edit").then((res) => {
  //     setInputs({
  //       return_date: res.data.return_date,
  //       assign_date: res.data.assign_date,
  //       asset_id: res.data.asset_id,
  //       hr_staff_basic_info_id: res.data.hr_staff_basic_info_id,
  //       location_id: res.data.location_id,
  //       factory_id: res.data.factory_id,
  //       department_id: res.data.department_id,
  //       remarks: res.data.remarks,
  //     });
  //   });
  //   Http.get("/asset").then((res) => {
  //     setAsset(res.data);
  //   });
  //   Http.get("/staff").then((res) => {
  //     setStaff(res.data);
  //   });
  //   Http.get("/location").then((res) => {
  //     setLocation(res.data);
  //   });
  //   Http.get("/factory").then((res) => {
  //     setFactory(res.data);
  //   });
  //   Http.get("/department").then((res) => {
  //     setDepartment(res.data);
  //   });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const [
          assetAssignRes,
          assetRes,
          staffRes,
          locationRes,
          factoryRes,
          departmentRes,
        ] = await Promise.all([
          Http.get("/asset-assign/" + id + "/edit"),
          Http.get("/asset"),
          Http.get("/staff"),
          Http.get("/location"),
          Http.get("/factory"),
          Http.get("/department"),
        ]);

        setInputs({
          return_date: assetAssignRes.data.return_date,
          assign_date: assetAssignRes.data.assign_date,
          asset_id: assetAssignRes.data.asset_id,
          hr_staff_basic_info_id: assetAssignRes.data.hr_staff_basic_info_id,
          location_id: assetAssignRes.data.location_id,
          factory_id: assetAssignRes.data.factory_id,
          department_id: assetAssignRes.data.department_id,
          remarks: assetAssignRes.data.remarks,
        });

        setAsset(assetRes.data);
        setStaff(staffRes.data);
        setLocation(locationRes.data);
        setFactory(factoryRes.data);
        setDepartment(departmentRes.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    Http.put("/asset-assign/" + id, inputs)
      .then((res) => {
        navigate("/asset-assign", {
          state: { message: res.data.message, type: res.data.type },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Asset Assign</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Asset Assign</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="assign_date"
                      value={inputs.assign_date || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Asset <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="asset_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Asset </option>
                      {Array.from(asset).map((item, index) => (
                        <option
                          value={item.id}
                          key={item.id}
                          selected={inputs.asset_id === item.id ? true : false}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">
                      Staff <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="hr_staff_basic_info_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Staff</option>
                      {Array.from(staff).map((item, index) => (
                        <option
                          value={item.id}
                          key={item.id}
                          selected={
                            inputs.hr_staff_basic_info_id === item.id
                              ? true
                              : false
                          }
                        >
                          {item.card_no} - {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">Location</label>
                    <select
                      className="form-control"
                      name="location_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Location</option>
                      {Array.from(location).map((item, index) => (
                        <option
                          value={item.id}
                          key={item.id}
                          selected={
                            inputs.location_id === item.id ? true : false
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label htmlFor="">Factory</label>
                    <select
                      className="form-control"
                      name="factory_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Factory</option>
                      {Array.from(factory).map((item, index) => (
                        <option
                          value={item.id}
                          key={item.id}
                          selected={
                            inputs.factory_id === item.id ? true : false
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">Department</label>
                    <select
                      className="form-control"
                      name="department_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {Array.from(department).map((item, index) => (
                        <option
                          value={item.id}
                          key={item.id}
                          selected={
                            inputs.department_id === item.id ? true : false
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label>
                      Description <i className="fa fa-star required"></i>
                    </label>
                    <textarea
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="col-lg">
                    <label>Return Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="return_date"
                      value={inputs.return_date || ""}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="col-lg">
                <label>Attachment</label>
                <input
                  type="file"
                  name="attachment"
                  className="form-control"
                  multiple
                />
              </div> */}
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                  <div className="col-6"></div>
                  <div className="col-3"></div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
