import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Alert from "../../../../Common/Alert";
import DatatableFunc from "../../../../Common/DatatableFunc";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import ReactDataTable from "../../../../Common/ReactDataTable";

const List = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [circular, setCircular] = useState({});
  const [workOrderAmount, setWorkOrderAmount] = useState({});
  const [alert, setAlert] = useState(null);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/asset-rfq-sent-from-vendor/" + id);
        setReceiveData(res.data.info);
        setCircular(res.data.circular);
        setWorkOrderAmount(res.data.amount);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
    DatatableFunc();
    fetchData();
  }, []);

  // const fetchData = () => {
  //   Http.get("/asset-rfq-sent-from-vendor/" + id).then((res) => {
  //     setReceiveData(res.data.info);
  //     setCircular(res.data.circular);
  //     setWorkOrderAmount(res.data.amount);
  //   });
  // };

  // useEffect(() => {
  //   DatatableFunc();
  //   fetchData();
  // }, []);
  const cols = [
    { field: "date", header: "Date" },
    { field: "vendor.name", header: "Vendor" },
    { field: "quotation_validity_date", header: "Validaty Date" },
    { field: "total_amount", header: "Total Amount" },
    { field: "vendor_id", header: "Work Order Amount" },
  ];

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">{circular.title}</h5>
            <Link
              to={{ pathname: "/asset-circular-compare/" + id }}
              className="btn btn-primary"
            >
              Compare
            </Link>
          </div>
          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              {loading ? (
                <ProgressSpinner />
              ) : (
                <>
                  <div className="row">
                    <div className="table-responsive p-0 pb-2">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              S/N
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Date
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Vendor
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Validaty Date
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Total Amount
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Work Order Amount
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {receiveData.map((item, index) => (
                            <tr key={item.id}>
                              <td className="text-xs">{index + 1}</td>
                              <td className="text-xs">{item.date}</td>
                              <td className="text-xs">{item.vendor.name}</td>
                              <td className="text-xs">
                                {item.quotation_validity_date}
                              </td>
                              <td className="text-xs">{item.total_amount}</td>
                              <td className="text-xs">
                                {workOrderAmount[item.vendor_id]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <ReactDataTable
                      cols={cols}
                      receiveData={receiveData}
                      isLoading={loading}
                      // actionTemplate={actionTemplate}
                      // deleteData={deleteData}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
