import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import { ProgressSpinner } from "primereact/progressspinner";

const Edit = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [staff, setStaff] = useState({});
  const [error, setError] = useState({});
  const { checkRequiredFields } = useRequiredFields();
  const [loading, setLoading] = useState(false);

  // const fetchData = () => {
  //   Http.get("/transaction/" + id + "/edit").then((res) => {
  //     setInputs({
  //       hr_staff_basic_info_id: res.data.hr_staff_basic_info_id,
  //       total_amount: res.data.total_amount,
  //       date: res.data.date,
  //       remarks: res.data.remarks,
  //     });
  //   });
  //   Http.get("/staff").then((res) => {
  //     setStaff(res.data);
  //   });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    setLoading(true);
  
    const fetchData = async () => {
      try {
        const transactionRes = await Http.get("/transaction/" + id + "/edit");
        setInputs({
          hr_staff_basic_info_id: transactionRes.data.hr_staff_basic_info_id,
          total_amount: transactionRes.data.total_amount,
          date: transactionRes.data.date,
          remarks: transactionRes.data.remarks,
        });
  
        const staffRes = await Http.get("/staff");
        setStaff(staffRes.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      inputs.type = "holiday";
      Http.put("/transaction/" + id, inputs)
        .then((res) => {
          navigate("/holiday", {
            state: { message: res.data.message, type: res.data.type },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  return (
    <div>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Holiday Salary Form</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Holiday Salary Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
    <ProgressSpinner />
  ) : (
    <>


            <div className="row">
              <div className="col-lg">
                <label htmlFor="">
                  Staff <i className="fa fa-star required"></i>
                </label>
                <select className="form-control" name="hr_staff_basic_info_id" onChange={handleChange}>
                  <option value="">Select Staff</option>
                  {Array.from(staff).map((item, index) => (
                    <option value={item.id} key={item.id} selected={inputs.hr_staff_basic_info_id === item.id ? true : false}>
                      {item.card_no + " - " + item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>
                  Total Amount <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="total_amount"
                  value={inputs.total_amount || ""}
                  onChange={handleChange}
                  data-required
                />
              </div>
              <div className="col-lg">
                <label>
                  Date <i className="fa fa-star required"></i>
                </label>
                <input type="date" className="form-control" name="date" value={inputs.date || ""} onChange={handleChange} data-required />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>Remarks</label>
                <textarea className="form-control" name="remarks" value={inputs.remarks || ""} onChange={handleChange} placeholder="Remarks" />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
            </div>
    </>)}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
