import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";

const EditLeaveRules = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [department, setDepartment] = useState([]);
  const [error, setError] = useState({});
  const { checkRequiredFields } = useRequiredFields();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [leaveRulesRes, departmentRes] = await Promise.all([Http.get(`/leave-rules/${id}/edit`), Http.get("/department")]);

        setInputs({
          type: leaveRulesRes.data.type,
          start_date: leaveRulesRes.data.start_date,
          end_date: leaveRulesRes.data.end_date,
          total_days: leaveRulesRes.data.total_days,
          department_id: leaveRulesRes.data.department_id,
        });

        setDepartment(departmentRes.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.put("/leave-rules/" + id, inputs)
        .then((res) => {
          navigate("/leave-rules", {
            state: { message: res.data.message, type: res.data.type },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Leave Rules</h5>
        </div>

        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Leave Rules Form</h6>

            {loading ? (
              <ProgressSpinner />
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg">
                    <FormValidation error={error} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold">Department</label>
                    <select className="form-control" name="department_id" value={inputs.department_id} onChange={handleChange}>
                      <option value="all">All</option>
                      {department?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label className="font-weight-bold">Type</label>
                    <select className="form-control" name="type" value={inputs.type} onChange={handleChange}>
                      <option value="casual">casual</option>
                      <option value="sick">sick</option>
                      <option value="earn">earn</option>
                      <option value="others">others</option>
                    </select>
                  </div>
                  {inputs.type == "earn" ? (
                    <div className="col-lg">
                      <label className="font-weight-bold">Earn Eligable Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Eligable Date"
                        name="earn_eligable_date"
                        value={inputs.earn_eligable_date || ""}
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Total Days <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Total Days"
                      name="total_days"
                      value={inputs.total_days || ""}
                      onChange={handleChange}
                      data-required
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default EditLeaveRules;
