import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../../Common/Alert";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";

import useAlertFromLocation from "../../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../../hooks/useDeleteData";
import ReactDataTable from "../../../../Common/ReactDataTable";

const List = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/base");
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // to delete data call
  const deleteData = useDeleteData(fetchData);
  // to show alert message
  const alert = useAlertFromLocation();

  // columns for the datatable
  const cols = [
    { field: "name", header: "Base" },
    {
      field: (item) => (item.region ? item.region.name : ""),
      header: "Region",
    },
    {
      field: (item) => (item.area ? item.area.name : ""),
      header: "Area",
    },
    { field: "started_from", header: "Started From" },
    { field: "remarks", header: "Remarks" },
    { field: "status", header: "Status" },
  ];

  // const deleteData = (id, deleteApiUrl, showMsg) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
  //         setReceiveData(res.data);
  //         Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
  //       });
  //     }
  //   });
  // };

  return (
    <div>
      <IsLogin></IsLogin>
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Base</h5>
            <Link to="/createbase" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Base
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <div className="row">
                {/* <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">S/N</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Region</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Area</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Base</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Started From</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Remarks</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Status</th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">{item.region ? item.region.name : ""}</td>
                          <td className="text-xs">{item.area ? item.area.name : ""}</td>
                          <td className="text-xs">{item.name}</td>
                          <td className="text-xs">{item.started_from}</td>
                          <td className="text-xs">{item.remarks}</td>
                          <td className="text-xs">{item.status}</td>
                          <td className="text-xs">
                            <Link to={{ pathname: "/editbase/" + item.id }} className="btn btn-primary btn-sm">
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>
                            <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(item.id, "base", "Base")}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
                <ReactDataTable cols={cols} receiveData={receiveData} isLoading={loading} actionTemplate={actionTemplate} deleteData={deleteData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: "/editbase/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      {/* <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(rowData.id, "deleteBase", "Base")} title="Delete">
        <i className="fa fa-trash"></i>
      </button> */}
    </div>
  );
};

export default List;
