import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

const CreateRegion = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [staff, setStaff] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { checkRequiredFields } = useRequiredFields();

  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/staff2");
      setStaff(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  // Default Load
  useEffect(() => {
    fetchData();
  }, []);
  // End Default Load

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.post("/special-late-facility", inputs)
        .then((res) => {
          navigate("/special-late-facility", {
            state: { message: res.data.message, type: "success" },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Special Late Facility</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Special Late Facility Form</h6>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <FormValidation error={error} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold">Staff</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={staff[0]}
                      name="hr_staff_basic_info_id"
                      options={staff}
                      onChange={selectHandleChange}
                    />
                    {/* <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={staff[0]}
                  name="hr_staff_basic_info_id"
                  options={staff}
                  onChange={selectHandleChange}
                /> */}

                    {/* <select
                  className="form-control"
                  name="hr_staff_basic_info_id"
                  onChange={handleChange}
                >
                  <option value="">Select Staff</option>
                  {Array.from(staff).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.card_no + " - " + item.name}
                    </option>
                  ))}
                </select> */}
                  </div>
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Year <i className="fa fa-star required"></i>
                    </label>
                    <input type="text" className="form-control" name="year" value={inputs.year || ""} onChange={handleChange} data-required />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default CreateRegion;
