import React from "react";
import {
  Area,
  // Area Chart
  AreaChart,
  Bar,
  // For Bar Chart
  BarChart,
  CartesianGrid,
  Cell,
  // ComposedChart,
  ComposedChart,
  Legend,
  Line,
  // For Graph Chart
  LineChart,
  Pie,
  // For Pie Chart
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// Example data
const data = [
  { name: "October", earnings: 4000 },
  { name: "November", earnings: 3000 },
  { name: "December", earnings: 2000 },
  { name: "January", earnings: 2780 },
  { name: "February", earnings: 1890 },
];

// Example data
const pieChart = [
  { name: "Dhaka South Region", value: 400 },
  { name: "Dhaka North Region", value: 300 },
  { name: "Savar Region", value: 300 },
  { name: "Narayanganj Region", value: 200 },
];

const barChart = [
  { name: "Aug", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Sep", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Oct", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Nov", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Dec", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Jan", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Feb", uv: 3490, pv: 4300, amt: 2100 },
];

const donutChart = [
  { name: "Dhaka South Region", value: 400 },
  { name: "Dhaka North Region", value: 300 },
  { name: "Savar Region", value: 300 },
  { name: "Narayanganj Region", value: 200 },
];

// Area Chart
// Sample data
const areaChart = [
  { name: "Dhaka", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Barishal", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Chittagong", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Mymensingh", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Sylhet", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Rangpur", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Jassore", uv: 3490, pv: 4300, amt: 2100 },
];

const ComposedChartData = [
  {
    name: "Feb",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "Jan",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "Dec",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "Nov",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "Oct",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "Sep",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "Aug",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "July",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "June",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "May",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "Apr",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "March",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
];

// Example colors for each slice
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const Admin = () => {
  return (
    <div>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Dashboard</h5>
          <form id="searchBar" className="search-bar" action="index.html">
            <div className="form-control-wrapper">
              <input
                type="search"
                className="form-control bd-0"
                placeholder="Search..."
              />
            </div>
            {/* form-control-wrapper */}
            <button id="searchBtn" className="btn btn-orange">
              <i className="fa fa-search" />
            </button>
          </form>
          {/* search-bar */}
        </div>

        {/* am-pagetitle */}
        <div className="am-pagebody">
          <div className="row row-sm">
            <div className="col-lg-4">
              {/* <div className="card">
                <div className="overlay-body pd-x-20 pd-t-20">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="tx-12 tx-uppercase tx-inverse tx-bold mg-b-5">Todays Earnings</h6>
                      <p className="tx-12">November 21, 2023</p>
                    </div>
                    <a className="tx-gray-600 hover-info">
                      <i className="icon ion-more tx-16 lh-0" />
                    </a>
                  </div>
                  
                  <h2 className="mg-b-5 tx-inverse tx-lato">12,212</h2>
                  <p className="tx-12 mg-b-0">Earnings before taxes.</p>
                </div>

                <div id="rs1" className="wd-100p ht-200 rickshaw_graph">
                  <svg>
                    <g>
                      <path
                        d="M0,150Q45.58666666666667,174.16666666666666,52.6,175C63.12,176.25,94.68,162.5,105.2,162.5S147.27999999999997,175,157.79999999999998,175S199.88,161.25,210.4,162.5S252.48000000000002,187.5,263,187.5S305.08,163.75,315.59999999999997,162.5S357.68,176.25,368.2,175S410.28000000000003,153.75,420.8,150S462.88000000000005,141.25,473.40000000000003,137.5Q480.41333333333336,135,526,112.5L526,200Q480.41333333333336,200,473.40000000000003,200C462.88000000000005,200,431.32,200,420.8,200S378.71999999999997,200,368.2,200S326.11999999999995,200,315.59999999999997,200S273.52,200,263,200S220.92000000000002,200,210.4,200S168.32,200,157.79999999999998,200S115.72,200,105.2,200S63.12,200,52.6,200Q45.58666666666667,200,0,200Z"
                        className="area"
                        fill="#fdc390"
                      ></path>
                      <path
                        d="M0,150Q45.58666666666667,174.16666666666666,52.6,175C63.12,176.25,94.68,162.5,105.2,162.5S147.27999999999997,175,157.79999999999998,175S199.88,161.25,210.4,162.5S252.48000000000002,187.5,263,187.5S305.08,163.75,315.59999999999997,162.5S357.68,176.25,368.2,175S410.28000000000003,153.75,420.8,150S462.88000000000005,141.25,473.40000000000003,137.5Q480.41333333333336,135,526,112.5"
                        className="line"
                        fill="none"
                        stroke="#FB9337"
                        strokeWidth="2"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div> */}

              {/* Card 1 */}
              <div className="card" style={{ padding: "1rem" }}>
                <div className="overlay-body pd-x-20 pd-t-20">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="tx-12 tx-uppercase tx-inverse tx-bold mg-b-5">
                        Todays Earnings
                      </h6>
                      <p className="tx-12">November 21, 2023</p>
                    </div>
                    <a className="tx-gray-600 hover-info">
                      <i className="icon ion-more tx-16 lh-0"></i>
                    </a>
                  </div>
                  <h2 className="mg-b-5 tx-inverse tx-lato">12,212</h2>
                  <p className="tx-12 mg-b-40">Earnings before taxes.</p>
                </div>
                <div id="rs1" className="wd-100p ht-200 rickshaw_graph">
                  <svg viewBox="0 0 526 200" preserveAspectRatio="none">
                    <g>
                      <path
                        d="M0,150Q45.58666666666667,174.16666666666666,52.6,175C63.12,176.25,94.68,162.5,105.2,162.5S147.27999999999997,175,157.79999999999998,175S199.88,161.25,210.4,162.5S252.48000000000002,187.5,263,187.5S305.08,163.75,315.59999999999997,162.5S357.68,176.25,368.2,175S410.28000000000003,153.75,420.8,150S462.88000000000005,141.25,473.40000000000003,137.5Q480.41333333333336,135,526,112.5L526,200Q480.41333333333336,200,473.40000000000003,200C462.88000000000005,200,431.32,200,420.8,200S378.71999999999997,200,368.2,200S326.11999999999995,200,315.59999999999997,200S273.52,200,263,200S220.92000000000002,200,210.4,200S168.32,200,157.79999999999998,200S115.72,200,105.2,200S63.12,200,52.6,200Q45.58666666666667,200,0,200Z"
                        className="area"
                        fill="#fdc390"
                      ></path>
                      <path
                        d="M0,150Q45.58666666666667,174.16666666666666,52.6,175C63.12,176.25,94.68,162.5,105.2,162.5S147.27999999999997,175,157.79999999999998,175S199.88,161.25,210.4,162.5S252.48000000000002,187.5,263,187.5S305.08,163.75,315.59999999999997,162.5S357.68,176.25,368.2,175S410.28000000000003,153.75,420.8,150S462.88000000000005,141.25,473.40000000000003,137.5Q480.41333333333336,135,526,112.5"
                        className="line"
                        fill="none"
                        stroke="#FB9337"
                        strokeWidth="2"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              {/* card */}
            </div>
            {/* col-4 */}

            {/* Card 2 */}
            <div className="col-lg-4 mg-t-15 mg-sm-t-20 mg-lg-t-0">
              <div className="card" style={{ padding: "1rem" }}>
                <div className="overlay-body pd-x-20 pd-t-20">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="tx-12 tx-uppercase tx-inverse tx-bold mg-b-5">
                        This Weeks Earnings
                      </h6>
                      <p className="tx-12">November 20 - 27, 2023</p>
                    </div>
                    <a className="tx-gray-600 hover-info">
                      <i className="icon ion-more tx-16 lh-0" />
                    </a>
                  </div>

                  <h2 className="mg-b-5 tx-inverse tx-lato">28,746</h2>
                  <p className="tx-12 mg-b-40">Earnings before taxes.</p>
                </div>

                <div id="rs2" className="wd-100p ht-200 rickshaw_graph">
                  <svg viewBox="0 0 526 200" preserveAspectRatio="none">
                    <g>
                      <path
                        d="M0,175Q45.58666666666667,163.83333333333334,52.6,162.5C63.12,160.5,94.68,155,105.2,155S147.27999999999997,163,157.79999999999998,162.5S199.88,148.75,210.4,150S252.48000000000002,173.75,263,175S305.08,162.5,315.59999999999997,162.5S357.68,176.25,368.2,175S410.28000000000003,153.75,420.8,150S462.88000000000005,140,473.40000000000003,137.5Q480.41333333333336,135.83333333333334,526,125L526,200Q480.41333333333336,200,473.40000000000003,200C462.88000000000005,200,431.32,200,420.8,200S378.71999999999997,200,368.2,200S326.11999999999995,200,315.59999999999997,200S273.52,200,263,200S220.92000000000002,200,210.4,200S168.32,200,157.79999999999998,200S115.72,200,105.2,200S63.12,200,52.6,200Q45.58666666666667,200,0,200Z"
                        className="area"
                        fill="#8bd0db"
                      ></path>
                      <path
                        d="M0,175Q45.58666666666667,163.83333333333334,52.6,162.5C63.12,160.5,94.68,155,105.2,155S147.27999999999997,163,157.79999999999998,162.5S199.88,148.75,210.4,150S252.48000000000002,173.75,263,175S305.08,162.5,315.59999999999997,162.5S357.68,176.25,368.2,175S410.28000000000003,153.75,420.8,150S462.88000000000005,140,473.40000000000003,137.5Q480.41333333333336,135.83333333333334,526,125"
                        className="line"
                        fill="none"
                        stroke="#17A2B8"
                        strokeWidth="2"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-4 mg-t-15 mg-sm-t-20 mg-lg-t-0">
              <div className="card" style={{ padding: "1rem" }}>
                <div className="overlay-body pd-x-20 pd-t-20">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="tx-12 tx-uppercase tx-inverse tx-bold mg-b-5">
                        This Months Earnings
                      </h6>
                      <p className="tx-12">November 1 - 30, 2023</p>
                    </div>
                    <a className="tx-gray-600 hover-info">
                      <i className="icon ion-more tx-16 lh-0" />
                    </a>
                  </div>

                  <h2 className="mg-b-5 tx-inverse tx-lato">72,118</h2>
                  <p className="tx-12 mg-b-40">Earnings before taxes.</p>
                </div>
                <div id="rs3" className="wd-100p ht-200 rickshaw_graph">
                  <svg viewBox="0 0 526 200" preserveAspectRatio="none">
                    <g>
                      <path
                        d="M0,150Q45.58666666666667,174.16666666666666,52.6,175C63.12,176.25,94.68,162.5,105.2,162.5S147.27999999999997,175,157.79999999999998,175S199.88,161.25,210.4,162.5S252.48000000000002,187.5,263,187.5S305.08,163.75,315.59999999999997,162.5S357.68,176.25,368.2,175S410.28000000000003,153.75,420.8,150S462.88000000000005,137.5,473.40000000000003,137.5Q480.41333333333336,137.5,526,150L526,200Q480.41333333333336,200,473.40000000000003,200C462.88000000000005,200,431.32,200,420.8,200S378.71999999999997,200,368.2,200S326.11999999999995,200,315.59999999999997,200S273.52,200,263,200S220.92000000000002,200,210.4,200S168.32,200,157.79999999999998,200S115.72,200,105.2,200S63.12,200,52.6,200Q45.58666666666667,200,0,200Z"
                        className="area"
                        fill="#a5ecb5"
                      ></path>
                      <path
                        d="M0,150Q45.58666666666667,174.16666666666666,52.6,175C63.12,176.25,94.68,162.5,105.2,162.5S147.27999999999997,175,157.79999999999998,175S199.88,161.25,210.4,162.5S252.48000000000002,187.5,263,187.5S305.08,163.75,315.59999999999997,162.5S357.68,176.25,368.2,175S410.28000000000003,153.75,420.8,150S462.88000000000005,137.5,473.40000000000003,137.5Q480.41333333333336,137.5,526,150"
                        className="line"
                        fill="none"
                        stroke="#28A745"
                        strokeWidth="2"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-sm mg-t-15 mg-sm-t-20">
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-40">
                <h6 className="card-body-title">Primary Sales</h6>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="earnings"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              {/* card */}
            </div>

            <div className="col-md-6">
              <div className="card pd-20 pd-sm-40">
                <h6 className="card-body-title">Order Vs Delivery Compare</h6>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart width={500} height={400} data={barChart}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="pv" fill="#8884d8" />
                    <Bar dataKey="uv" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              {/* card */}
            </div>
          </div>

          <div className="row row-sm mg-t-15 mg-sm-t-20">
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-40">
                <h6 className="card-body-title">Region Wise Sales</h6>
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={pieChart}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={(entry) => entry.name}
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card pd-20 pd-sm-40">
                <h6 className="card-body-title">Zone Wise Sales</h6>
                <ResponsiveContainer width="100%" height={400}>
                  <AreaChart
                    width={500}
                    height={400}
                    data={areaChart}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="uv"
                      stackId="1"
                      stroke="#8884d8"
                      fill="#8884d8"
                    />
                    <Area
                      type="monotone"
                      dataKey="pv"
                      stackId="1"
                      stroke="#82ca9d"
                      fill="#82ca9d"
                    />
                    <Area
                      type="monotone"
                      dataKey="amt"
                      stackId="1"
                      stroke="#ffc658"
                      fill="#ffc658"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          <div className="row row-sm mg-t-15 mg-sm-t-20">
            <div className="col-md-12">
              <div className="card pd-20 pd-sm-40">
                <h6 className="card-body-title">Sale Compare Monthly</h6>
                <ResponsiveContainer width="100%" height={400}>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={ComposedChartData}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Area
                      type="monotone"
                      dataKey="amt"
                      fill="#8884d8"
                      stroke="#8884d8"
                    />
                    <Bar dataKey="pv" barSize={20} fill="#413ea0" />
                    <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
              {/* card */}
            </div>
          </div>
        </div>
        {/* am-pagebody */}
        <div className="am-footer">
          <span>
            Copyright ©. All Rights Reserved.{" "}
            <a href="http://limerickbd.com/">Limerick Resources Limited</a>
          </span>
        </div>
        {/* am-footer */}
      </div>
      ;
    </div>
  );
};

export default Admin;
