import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";

const Edit = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [location, setLocation] = useState({});
  const [factory, setFactory] = useState({});
  const [department, setDepartment] = useState({});
  const [asset, setAsset] = useState({});
  const [unit, setUnit] = useState({});
  const [selectedFile, setSelectedFile] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [list, setList] = useState({});
  const [attachment, setAttachment] = useState({});
  const [error, setError] = useState({});
  const [url, setUrl] = useState(Http.getUri());
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleFileChange = (e) => {
    const fileArray = [];
    const files = e.target.files;
    Object.keys(files).forEach((key) => {
      fileArray.push(e.target.files[key]);
    });
    setSelectedFile(fileArray);
    setIsSelected(true);
  };

  // Set Experience State
  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };
  // const fetchData = () => {
  //   Http.get("/location").then((res) => {
  //     setLocation(res.data);
  //   });
  //   Http.get("/factory").then((res) => {
  //     setFactory(res.data);
  //   });
  //   Http.get("/department").then((res) => {
  //     setDepartment(res.data);
  //   });
  //   Http.get("/asset").then((res) => {
  //     setAsset(res.data);
  //   });
  //   Http.get("/unit").then((res) => {
  //     setUnit(res.data);
  //   });
  //   Http.get("/asset-circular-rfq/" + id + "/edit").then((res) => {
  //     setInputs({
  //       date: res.data.date,
  //       title: res.data.title,
  //       subject: res.data.subject,
  //       description: res.data.description,
  //       circular_no: res.data.circular_no,
  //       last_date_of_quotation: res.data.last_date_of_quotation,
  //       quotation_validaty: res.data.quotation_validaty,
  //       company_location_id: res.data.company_location_id,
  //       factory_id: res.data.factory_id,
  //       department_id: res.data.department_id,
  //       status: res.data.status,
  //     });

  //     const listInfo = [];
  //     Object.keys(res.data.items).forEach((key) => {
  //       listInfo.push({
  //         asset_id: res.data.items[key].asset_id,
  //         unit_id: res.data.items[key].unit_id,
  //         qty: res.data.items[key].qty,
  //         desc: res.data.items[key].desc,
  //       });
  //     });
  //     setList(listInfo);

  //     const attachmentInfo = [];
  //     Object.keys(res.data.attachment).forEach((key) => {
  //       attachmentInfo.push({ name: res.data.attachment[key].attachment });
  //     });
  //     // console.log("lorem" + attachmentInfo);
  //     setAttachment(attachmentInfo);
  //   });
  //   Http.get("/unit").then((res) => {
  //     setUnit(res.data);
  //   });
  // };

  // useEffect(() => {
  //   fetchData();

  //   const splitUrl = url.split("/");
  //   let fullUrl = "";
  //   for (var i = 0; i < splitUrl.length - 1; i++) {
  //     fullUrl += splitUrl[i] + "/";
  //   }
  //   setUrl(fullUrl);
  // }, []);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const [
          locationRes,
          factoryRes,
          departmentRes,
          assetRes,
          unitRes,
          rfqRes,
        ] = await Promise.all([
          Http.get("/location"),
          Http.get("/factory"),
          Http.get("/department"),
          Http.get("/asset"),
          Http.get("/unit"),
          Http.get("/asset-circular-rfq/" + id + "/edit"),
        ]);

        setLocation(locationRes.data);
        setFactory(factoryRes.data);
        setDepartment(departmentRes.data);
        setAsset(assetRes.data);
        setUnit(unitRes.data);

        setInputs({
          date: rfqRes.data.date,
          title: rfqRes.data.title,
          subject: rfqRes.data.subject,
          description: rfqRes.data.description,
          circular_no: rfqRes.data.circular_no,
          last_date_of_quotation: rfqRes.data.last_date_of_quotation,
          quotation_validaty: rfqRes.data.quotation_validaty,
          company_location_id: rfqRes.data.company_location_id,
          factory_id: rfqRes.data.factory_id,
          department_id: rfqRes.data.department_id,
          status: rfqRes.data.status,
        });

        const listInfo = rfqRes.data.items.map((item) => ({
          asset_id: item.asset_id,
          unit_id: item.unit_id,
          qty: item.qty,
          desc: item.desc,
        }));
        setList(listInfo);

        const attachmentInfo = rfqRes.data.attachment.map((att) => ({
          name: att.attachment,
        }));
        setAttachment(attachmentInfo);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const splitUrl = url.split("/");
    const fullUrl = splitUrl.slice(0, -1).join("/") + "/";
    setUrl(fullUrl);
  }, []);

  // Add More List
  const addMoreList = () => {
    setList([...list, { asset_id: "", unit_id: "", qty: "", desc: "" }]); // Add One More List
  };
  // Remove List
  const removeList = (index) => {
    const inputInfo = [...list]; // Load All Existing experience State Data
    inputInfo.splice(index, 1); // Remove Experience by Index/Click Event Wise
    setList(inputInfo); // Replace Existing experience into State
  };

  const formSubmit = () => {
    const listArr = [];
    Object.keys(list).forEach((key) => {
      listArr.push(list[key]);
    });

    inputs.file = selectedFile;
    inputs.items = listArr;

    Http.put("/asset-circular-rfq/" + id, inputs)
      .then((res) => {
        navigate("/asset-circular-rfq", {
          state: { message: res.data.message, type: res.data.type },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Circular RFQ Form</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
              Edit Circular RFQ Form
            </h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Circular no <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="circular_no"
                      value={inputs.circular_no || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Title <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={inputs.title || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Subject <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      value={inputs.subject || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>Last Date Of Quotation</label>
                    <input
                      type="date"
                      className="form-control"
                      name="last_date_of_quotation"
                      value={inputs.last_date_of_quotation || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>Quotation Validaty</label>
                    <input
                      type="date"
                      className="form-control"
                      name="quotation_validaty"
                      value={inputs.quotation_validaty || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">Location</label>
                    <select
                      className="form-control"
                      name="company_location_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Location</option>
                      {Array.from(location).map((item, index) => (
                        <option
                          value={item.id}
                          key={item.id}
                          selected={
                            inputs.company_location_id === item.id
                              ? true
                              : false
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">Factory</label>
                    <select
                      className="form-control"
                      name="factory_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Factory</option>
                      {Array.from(factory).map((item, index) => (
                        <option
                          value={item.id}
                          key={item.id}
                          selected={
                            inputs.factory_id === item.id ? true : false
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">Department</label>
                    <select
                      className="form-control"
                      name="department_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {Array.from(department).map((item, index) => (
                        <option
                          value={item.id}
                          key={item.id}
                          selected={
                            inputs.department_id === item.id ? true : false
                          }
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      name="description"
                      value={inputs.description || ""}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-10"></div>
                  <div className="col-2">
                    <button
                      className="btn btn-info btn-block mg-b-10"
                      onClick={addMoreList}
                    >
                      <i className="fa fa-plus mg-r-10"></i> ADD More
                    </button>
                  </div>
                  <table className="table table-sm align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Product
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Unit
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Qty
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Description
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from(list).map((x, i) => (
                        <tr key={i}>
                          <td>
                            <select
                              className="form-control"
                              name="asset_id"
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value="">Select Product</option>
                              {Array.from(asset).map((item, index) => (
                                <option
                                  value={item.id}
                                  key={item.id}
                                  selected={
                                    x.asset_id === item.id ? true : false
                                  }
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <select
                              className="form-control"
                              name="unit_id"
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value="">Select Unit</option>
                              {Array.from(unit).map((item, index) => (
                                <option
                                  value={item.id}
                                  key={item.id}
                                  selected={
                                    x.unit_id === item.id ? true : false
                                  }
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="qty"
                              value={x.qty || ""}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <textarea
                              className="form-control"
                              name="desc"
                              value={x.desc || ""}
                              onChange={(e) => handleInputChange(e, i)}
                            ></textarea>
                          </td>
                          <td>
                            {list.length > 1 ? (
                              <button
                                className="btn btn-danger btn-block"
                                onClick={() => removeList(i)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* <div className="row mt-3">
              <div className="col-lg">
                <label>Attachment</label>
                <input
                  type="file"
                  name="attachment"
                  className="form-control"
                  multiple
                  onChange={handleFileChange}
                />
                {Array.from(attachment).map((x, i) => (
                  <a
                    href={url + "circular/" + x.name}
                    target="_blank"
                    className="btn btn-info"
                    style={{ marginRight: "10px", marginTop: "10px" }}
                  >
                    Download
                  </a>
                ))}
              </div>
            </div> */}

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
