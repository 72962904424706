import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import { ProgressSpinner } from "primereact/progressspinner";

const Create = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const [inputs, setInputs] = useState({ type, mushok_6_4_show: true }); // initialized type from useParams
  const [unit, setUnit] = useState([]);
  const [unitSupply, setUnitSupply] = useState([]);
  const [category, setCategory] = useState([]);
  const [error, setError] = useState({});

  const [showAccountsForm, setShowAccountsForm] = useState(false);
  const [loading, setLoading] = useState(false);
useEffect(() => {
  setLoading(true);
  if (inputs.type === "finished_goods") {
    setShowAccountsForm(true);
  } else {
    setShowAccountsForm(false);
  }

  const fetchData = async () => {
    try {
      const unitData = await Http.get("/unitSelect2");
    const categoryData = await Http.get("/productCatSelect2");
    const codeData = await Http.get("/last-code-increment-by-one/products/code/P");

    setUnit(unitData.data);
    setUnitSupply(unitData.data);
    setCategory(categoryData.data);
    setInputs((prevInputs) => ({
      ...prevInputs,
      code: codeData.data,
    }));
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };
  fetchData();
}, [inputs.type]);

  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Default Load
  // const fetchData = async () => {
  //   const unitData = await Http.get("/unitSelect2");
  //   const categoryData = await Http.get("/productCatSelect2");
  //   const codeData = await Http.get("/last-code-increment-by-one/products/code/P");

  //   setUnit(unitData.data);
  //   setUnitSupply(unitData.data);
  //   setCategory(categoryData.data);
  //   setInputs((prevInputs) => ({
  //     ...prevInputs,
  //     code: codeData.data,
  //   }));
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (inputs.type === "finished_goods") {
  //     setShowAccountsForm(true);
  //   } else {
  //     setShowAccountsForm(false);
  //   }
  // }, [inputs.type]);

  const formSubmit = () => {
    setInputs((prevInputs) => ({ ...prevInputs, type: type }));
    Http.post("/product", inputs)
      .then((res) => {
        navigate("/product", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Product</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Create Product Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
    <ProgressSpinner />
  ) : ( <>
  


  

            <div className="row">
              <div className="col-lg">
                <label>
                  Code <i className="fa fa-star required"></i>
                </label>
                <input type="text" className="form-control" name="code" value={inputs.code || ""} onChange={handleChange} />
              </div>
              <div className="col-lg">
                <label>
                  Name <i className="fa fa-star required"></i>
                </label>
                <input type="text" className="form-control" name="name" value={inputs.name || ""} onChange={handleChange} />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Unit <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={unit[0]}
                  name="unit_id"
                  options={unit}
                  onChange={selectHandleChange}
                />
              </div>
              <div className="col-lg">
                <label>
                  Price <i className="fa fa-star required"></i>
                </label>
                <input type="text" className="form-control" name="price" value={inputs.price || ""} onChange={handleChange} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  SKU <i className="fa fa-star required"></i>
                </label>
                <input type="text" className="form-control" name="sku" value={inputs.sku || ""} onChange={handleChange} />
              </div>
              <div className="col-lg">
                <label>Pack Size</label>
                <input type="text" className="form-control" name="pack_size" value={inputs.pack_size || ""} onChange={handleChange} />
              </div>
              <div className="col-lg">
                <label>Stock</label>
                <input type="text" className="form-control" name="stock" value={inputs.stock || ""} onChange={handleChange} />
              </div>
              <div className="col-lg">
                <label>
                  Type <i className="fa fa-star required"></i>
                </label>
                <select className="form-control" name="type" value={inputs.type} onChange={handleChange}>
                  <option value="">Select Type</option>
                  <option value="raw_materials">Raw Materials</option>
                  <option value="packing_materials">Packing Materials</option>
                  <option value="finished_goods">Finished Goods</option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">
                  Category <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={category[0]}
                  name="category_id"
                  options={category}
                  onChange={selectHandleChange}
                />
              </div>
              <div className="col-lg">
                <label>Vat</label>
                <input type="text" className="form-control" name="vat" value={inputs.vat || ""} onChange={handleChange} />
              </div>
              <div className="col-lg">
                <label>Notes</label>
                <textarea className="form-control" name="notes" value={inputs.notes || ""} onChange={handleChange}></textarea>
              </div>
              <div className="col-lg">
                <label>Status</label>
                <select className="form-control" name="status" onChange={handleChange}>
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>

            {showAccountsForm && (
              <>
                <div className="row mt-5">
                  <div className="col-md-12 mb-3">
                    <h4 className="text-center">Accounts</h4>
                    <hr />
                  </div>

                  <div className="col-lg-6">
                    <label>STD For Accounts:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="std_price_accounts"
                      value={inputs.std_price_accounts || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>Vat For Accounts:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="vat_value_accounts"
                      value={inputs.vat_value_accounts || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 mt-5">
                    <h4 className="text-center">চালান</h4>
                    <hr />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>একক মূল্য(টাকায়):</label>
                    <input type="text" className="form-control" name="sdv_inv" value={inputs.sdv_inv || ""} onChange={handleChange} />
                  </div>
                  <div className="col-lg">
                    <label>সম্পুরক শুল্কের হার:</label>
                    <input type="text" className="form-control" name="sd_inv" value={inputs.sd_inv || ""} onChange={handleChange} />
                  </div>
                  <div className="col-lg">
                    <label>মূল্য সংযোজন করের হার/সুনির্দিষ্ট কর:</label>
                    <input type="text" className="form-control" name="vat_inv" value={inputs.vat_inv || ""} onChange={handleChange} />
                  </div>
                  <div className="col-lg">
                    <label>
                      সরবরাহের একক: <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={unitSupply[0]}
                      name="unit_supply"
                      options={unitSupply}
                      onChange={selectHandleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      পরিমাণ: <i className="fa fa-star required"></i>
                    </label>
                    <input type="text" className="form-control" name="unit_supply_qty" value={inputs.unit_supply_qty || ""} onChange={handleChange} />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="mushok_6_4_show"
                        className="form-check-input"
                        checked={inputs.mushok_6_4_show || false}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="flexCheckChecked">
                        Mushok 6.4 Show
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                  <i className="fa fa-send mg-r-10"></i> Create
                </button>
              </div>
            </div>
            </> )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
