import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
// import { LoaderComponent } from "../../../Common/LoaderComponent";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);

  // const fetchData = () => {
  //   Http.get("/budget-plan").then((res) => {
  //     setReceiveData(res.data);
  //   });
  // };
  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const res = await Http.get(`/budget-plan`);
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };
  const cols = [
    { field: "start_date", header: "Start Date" },
    { field: "end_date", header: "End Date" },
    { field: "message", header: "Message" },
    { field: "total", header: "Total" },
    { field: "created_by.name", header: "Created By" },
  ];

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Finance Plan</h5>
            <Link to="/create-budget-plan" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Finance Plan
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              {/* <div className="row">
                <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          S/N
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Start Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          End Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Message
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Total
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Created By
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">{item.start_date}</td>
                          <td className="text-xs">{item.end_date}</td>
                          <td className="text-xs">{item.message}</td>
                          <td className="text-xs">{item.total}</td>
                          <td className="text-xs">
                            {item.created_by ? item.created_by.name : ""}
                          </td>
                          <td className="text-xs">
                            <Link
                              to={{ pathname: "/edit-budget-plan/" + item.id }}
                              className="btn btn-primary btn-sm"
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              to={{ pathname: "/show-budget-plan/" + item.id }}
                              className="btn btn-info btn-sm ms-1"
                            >
                              <i className="fa fa-list" aria-hidden="true"></i>
                            </Link>
                            <button
                              className="btn btn-danger btn-sm ms-1"
                              onClick={() =>
                                deleteData(
                                  item.id,
                                  "budget-plan",
                                  "Budget Plan"
                                )
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div> */}
              <ReactDataTable
                cols={cols}
                receiveData={receiveData}
                isLoading={loading}
                actionTemplate={actionTemplate}
                deleteData={deleteData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const actionTemplate = (item, deleteData) => {
  return (
    <td className="text-xs">
      <Link
        to={{ pathname: "/edit-budget-plan/" + item.id }}
        className="btn btn-primary btn-sm"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      <Link
        to={{ pathname: "/show-budget-plan/" + item.id }}
        className="btn btn-info btn-sm ms-1"
      >
        <i className="fa fa-list" aria-hidden="true"></i>
      </Link>
      <button
        className="btn btn-danger btn-sm ms-1"
        onClick={() => deleteData(item.id, "budget-plan", "Budget Plan")}
      >
        <i className="fa fa-trash"></i>
      </button>
    </td>
  );
};
export default List;
