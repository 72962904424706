import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../../Common/Http";
import FormValidation from "../../../Common/FormValidation";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import { ProgressSpinner } from "primereact/progressspinner";

const Edit = (props) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [unit, setUnit] = useState({});
  const [category, setCategory] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  
    const fetchData = async () => {
      try {
        const assetRes = await Http.get("/asset/" + id + "/edit");
        setInputs({
          code: assetRes.data.code,
          name: assetRes.data.name,
          description: assetRes.data.notes,
          unit_id: assetRes.data.unit_id,
          asset_category_id: assetRes.data.asset_category_id,
          status: assetRes.data.status,
        });
  
        const unitRes = await Http.get("/unit");
        setUnit(unitRes.data);
  
        const categoryRes = await Http.get("/asset-active-category");
        setCategory(categoryRes.data);
  
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  // const fetchData = () => {
  //   Http.get("/asset/" + id + "/edit").then((res) => {
  //     setInputs({
  //       code: res.data.code,
  //       name: res.data.name,
  //       description: res.data.notes,
  //       unit_id: res.data.unit_id,
  //       asset_category_id: res.data.asset_category_id,
  //       status: res.data.status,
  //     });
  //   });
  //   Http.get("/unit").then((res) => {
  //     setUnit(res.data);
  //   });
  //   Http.get("/asset-active-category").then((res) => {
  //     setCategory(res.data);
  //   });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    Http.put("/asset/" + id, inputs)
      .then((res) => {
        navigate("/asset", {
          state: { message: res.data.message, type: res.data.type },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit asset Form</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit asset Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
    <ProgressSpinner />
  ) : (
    <>


            <div className="row">
              <div className="col-lg">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={inputs.code || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Unit</label>
                <select
                  className="form-control"
                  name="unit_id"
                  onChange={handleChange}
                >
                  <option value="">Select Unit</option>
                  {Array.from(unit).map((item, index) => (
                    <option
                      value={item.id}
                      key={item.id}
                      selected={inputs.unit_id === item.id ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">Category</label>
                <select
                  className="form-control"
                  name="category_id"
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {Array.from(category).map((item, index) => (
                    <option
                      value={item.id}
                      key={item.id}
                      selected={
                        inputs.asset_category_id === item.id ? true : false
                      }
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-lg">
                <label>Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={inputs.description || ""}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-lg-4">
                <label>Status</label>
                <select
                  className="form-control"
                  name="status"
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option
                    value="active"
                    selected={inputs.status === "active" ? true : false}
                  >
                    Active
                  </option>
                  <option
                    value="inactive"
                    selected={inputs.status === "inactive" ? true : false}
                  >
                    Inactive
                  </option>
                </select>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
            </div>
    </>)}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
