import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
// import { LoaderComponent } from "../../../Common/LoaderComponent";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [role, setRole] = useState(null);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);

  // const fetchData = () => {
  //   Http.get("/direct-purchase").then((res) => {
  //     setReceiveData(res.data);
  //   });

  //   Http.get("/staff-type-check").then((res) => {
  //     setRole(res.data);
  //   });
  // };
  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const resDp = await Http.get(`/direct-purchase`);
      const resRole = await Http.get(`/staff-type-check`);
      setReceiveData(resDp.data);
      setRole(resRole.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };
  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };
  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-xs">
        <Link
          to={{
            pathname: "/show-direct-purchase/" + item.id,
          }}
          className="btn btn-info btn-sm ms-1"
          title="Show"
        >
          <i className="fa fa-list ms-1" aria-hidden="true"></i>
        </Link>
        {(role === "admin" || role == "supply_chain") && (
          <>
            {item.grn.length == 0 ? (
              <Link
                to={{
                  pathname: "/edit-direct-purchase/" + item.id,
                }}
                className="btn btn-primary btn-sm ms-1"
                title="Edit"
              >
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </Link>
            ) : (
              ""
            )}
  
            {item.grn.length == 0 ? (
              <button
                title="Delete"
                className="btn btn-danger btn-sm ms-1"
                onClick={() =>
                  deleteData(item.id, "direct-purchase", "direct-purchase")
                }
              >
                <i className="fa fa-trash"></i>
              </button>
            ) : (
              ""
            )}
          </>
        )}
      </td>
    );
  };

  const cols = [
    { field: "date", header: "Date" },
    { field: "receipt_no", header: "Receipt No" },
    { field: "vendor.name", header: "Vendor" },
    { field: "factory.name", header: "Factory" },
    {
      // field: "total_amount",
      header: "Total Amount",
      field: (item) => parseFloat(item.total_amount).toFixed(2),
    },
    { field: "description", header: "Description" },
    { field: "created_by.name", header: "Created By" },
    {
      // field: "receive",
      header: "Receive",
      field: (item) => item.status !== "completed" ? (
        <Link
          className="btn btn-primary btn-sm ms-1"
          to={`/create-grn/${item.id}/dp`}
        >
          <i className="fa fa-send"></i> Receive
        </Link>
      ) : (
        <b>Completed</b>
      ),
    },
  ];
  

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Direct Purchase </h5>
            <Link to="/create-direct-purchase" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i> New
            </Link>
          </div>
          <div className="am-pagebody">
            <Alert alert={alert} />
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
              
              <ReactDataTable
                cols={cols}
                receiveData={receiveData}
                isLoading={loading}
                actionTemplate={actionTemplate}
                deleteData={deleteData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
