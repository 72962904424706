import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Http from "../Common/Http";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [notification, setNotification] = useState({});

  const fetchData = () => {
    Http.get("/me").then((res) => {
      setUser(res.data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchNotificationData = () => {
      Http.get("/unread-notification")
        .then((res) => {
          setNotification(res.data);
          document.getElementById("app-title").innerText = res.data.length > 0 ? `(${res.data.length}) Limtech ERP` : "Limtech ERP";
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
        });
    };

    const interval = setInterval(fetchNotificationData, 50000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const signOut = () => {
    localStorage.clear();
    navigate("/login");
    // window.location.href = "/login";
  };

  if (location.pathname == "/" || location.pathname == "/login") return null;

  return (
    <div>
      <div className="am-header">
        <div className="am-header-left">
          <a id="naviconLeft" className="am-navicon d-none d-lg-flex">
            <i className="icon ion-navicon-round" />
          </a>
          <a id="naviconLeftMobile" className="am-navicon d-lg-none">
            <i className="icon ion-navicon-round" />
          </a>
          <a href="index.html" className="am-logo">
            {/* Limtech ERP */}
            <img src="limtech-logo.png" alt="" height="60px" />
          </a>
        </div>
        {/* am-header-left */}
        <div className="am-header-right">
          <div className="dropdown dropdown-notification">
            <Link to="/notification" className="nav-link pd-x-7 pos-relative">
              <i className="icon ion-ios-bell-outline tx-24" />
              {notification.length > 0 ? <span className="square-8 bg-danger pos-absolute t-15 r-0 rounded-circle" /> : ""}
            </Link>
          </div>
          <div className="dropdown dropdown-notification">
            <Link to="/profile-edit" className="nav-link pd-x-7 pos-relative">
              <i className="icon ion-settings tx-24"></i>
            </Link>
          </div>
          <div className="dropdown dropdown-profile">
            <a className="nav-link nav-link-profile" data-toggle="dropdown">
              <img src="assets/img/default_user.png" style={{ marginTop: "-20px" }} className="wd-32 rounded-circle img-thumbnail" />
              <span className="logged-name">
                {user.name}
                <span className="hidden-xs-down" style={{ cursor: "pointer" }} onClick={signOut}>
                  <i></i> <br />
                  <i className="icon ion-power" /> Sign Out
                </span>
              </span>
            </a>
            <div className="dropdown-menu wd-200">
              <ul className="list-unstyled user-profile-nav">
                <li>
                  <a>
                    <i className="icon ion-ios-person-outline" /> Edit Profile
                  </a>
                </li>
                <li>
                  <a>
                    <i className="icon ion-ios-gear-outline" /> Settings
                  </a>
                </li>
                <li>
                  <a>
                    <i className="icon ion-ios-download-outline" /> Downloads
                  </a>
                </li>
                <li>
                  <a>
                    <i className="icon ion-ios-star-outline" /> Favorites
                  </a>
                </li>
                <li>
                  <a>
                    <i className="icon ion-ios-folder-outline" /> Collections
                  </a>
                </li>
                <li>
                  <a>
                    <i className="icon ion-power" /> Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* am-header-right */}
      </div>
      {/* am-header */}
    </div>
  );
};

export default Header;
