import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequiredFields from "../../../../../hooks/useRequiredFields";
import FormValidation from "../../../../Common/FormValidation";
import Http from "../../../../Common/Http";

const Edit = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [error, setError] = useState({});
  const { checkRequiredFields } = useRequiredFields();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/region/" + id + "/edit");
      setInputs({
        name: res.data.name,
        started_from: res.data.started_from,
        remarks: res.data.remarks,
        status: res.data.status,
      });
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.put("/region/" + id, inputs)
        .then((res) => {
          navigate("/region", {
            state: { message: res.data.message, type: res.data.type },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Region</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            {error && Object.keys(error).length > 0 && (
              <div className="row">
                <div className="col-lg">
                  <FormValidation error={error} />
                </div>
              </div>
            )}

            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold" htmlFor="">
                      Region Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Input Name"
                      data-required
                    />
                  </div>
                  {/* col */}
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold" htmlFor="">
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control select2"
                      name="started_from"
                      value={inputs.started_from || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                      data-required
                    />
                  </div>

                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold" htmlFor="">
                      Status
                    </label>
                    <select className="form-control" name="status" onChange={handleChange}>
                      <option value="active" selected={inputs.status === "active" ? true : false}>
                        Active
                      </option>
                      <option value="inactive" selected={inputs.status === "inactive" ? true : false}>
                        inactive
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label className="font-weight-bold" htmlFor="">
                      Remarks
                    </label>
                    <textarea className="form-control" name="remarks" value={inputs.remarks || ""} onChange={handleChange} placeholder="Remarks" />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
