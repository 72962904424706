import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import IsLogin from "../../../Common/IsLogin";
import FormValidation from "../../../Common/FormValidation";
import { ProgressSpinner } from "primereact/progressspinner";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [vendorCode, setVendorCode] = useState({});
  const [formErrors, setFormErrors] = useState({});
  // if upload has any File
  const [selectedFile1, setSelectedFile1] = useState();
  const [selectedFile2, setSelectedFile2] = useState();
  const [selectedFile3, setSelectedFile3] = useState();
  const [selectedFile4, setSelectedFile4] = useState();
  const [loading, setLoading] = useState(false);
useEffect(() => {
  setLoading(true);

  const fetchData = async () => {
    try {
      const res = await Http.get("/last-code-increment-by-one/vendors/code/V");
      setVendorCode(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };
  fetchData();
}, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // if upload has any File
  const handleFileChange1 = (e) => {
    setSelectedFile1(e.target.files[0]);
  };
  const handleFileChange2 = (e) => {
    setSelectedFile2(e.target.files[0]);
  };
  const handleFileChange3 = (e) => {
    setSelectedFile3(e.target.files[0]);
  };
  const handleFileChange4 = (e) => {
    setSelectedFile4(e.target.files[0]);
  };

  const formSubmit = () => {
    inputs.license_img = selectedFile1;
    inputs.vat_reg_img = selectedFile2;
    inputs.tin_img = selectedFile3;
    inputs.nid_img = selectedFile4;
    inputs.code = vendorCode;
    Http.post("/vendor", inputs)
      .then((res) => {
        navigate("/vendors", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
        setFormErrors(e.response.data.errors);

        console.log(formErrors.name[0]);
      });
  };

  // Default Load
  // const fetchData = () => {
  //   /*Http.get("/last-vendor-code").then((res) => {
  //     // Get Last Staff Card no
  //     let vendorCode = res.data;
  //     setVendorCode(++vendorCode);
  //   });*/

  //   Http.get("/last-code-increment-by-one/vendors/code/V").then((res) => {
  //     setVendorCode(res.data);
  //   });
  // };
  // useEffect(() => {
  //   fetchData(); // Get All Fetch Info/Data
  // }, []);
  // End Default Load

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Vendor</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Create Vendor Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
    <ProgressSpinner />
  ) : ( <>
  


  
  
            <div className="row">
              <div className="col-lg">
                <label>
                  Code <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={vendorCode || ""}
                  onChange={handleChange}
                  placeholder="Input Code"
                />
                {/* {formErrors.name && (
                  <span className="error">{formErrors.code[0]}</span>
                )} */}
              </div>
              {/* col */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>
                  Name <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                  placeholder="Input Name"
                  required
                />
                {/* {formErrors.name && (
                  <span className="error">{formErrors.name[0]}</span>
                )} */}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  Contact Person <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="contact_person"
                  value={inputs.contact_person || ""}
                  onChange={handleChange}
                  placeholder="Input contact person"
                />
              </div>
              {/* col */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>
                  Contact Phone <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="contact_phone"
                  value={inputs.contact_phone || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg">
                <label>Secondary Phone</label>
                <input
                  type="text"
                  className="form-control"
                  name="secondary_phone"
                  value={inputs.secondary_phone || ""}
                  onChange={handleChange}
                  placeholder="Input secondary phone"
                />
              </div>
              {/* col */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={inputs.email || ""}
                  onChange={handleChange}
                  placeholder="Input email"
                  required
                />
              </div>
            </div>
            {/* 8 9 12  */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>Website</label>
                <input
                  type="text"
                  className="form-control"
                  name="website"
                  value={inputs.website || ""}
                  onChange={handleChange}
                  placeholder="Input website"
                />
              </div>
              {/* col */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Previous Due</label>
                <input
                  type="number"
                  className="form-control"
                  name="previous_due"
                  value={inputs.previous_due || ""}
                  onChange={handleChange}
                  placeholder="Input previous due"
                />
              </div>
            </div>

            {/* NID */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  NID No <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="nid"
                  value={inputs.nid || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg">
                <label>NID Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="nid_img"
                  onChange={handleFileChange4}
                  placeholder="Input license no"
                />
              </div>
            </div>

            {/* license */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  License No <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="license_no"
                  value={inputs.license_no || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Validity Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="license_no_validaty_date"
                  value={inputs.license_no_validaty_date || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
              <div className="col-lg">
                <label>License Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="license_img"
                  onChange={handleFileChange1}
                  placeholder="Input license no"
                />
              </div>
            </div>

            {/* Vat */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>Vat Reg. No</label>
                <input
                  type="text"
                  className="form-control"
                  name="vat_reg_no"
                  value={inputs.vat_reg_no || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Validity Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="vat_reg_no_validaty_date"
                  value={inputs.vat_reg_no_validaty_date || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
              <div className="col-lg">
                <label>Vat Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="vat_reg_img"
                  onChange={handleFileChange2}
                  placeholder="Input license no"
                />
              </div>
            </div>

            {/* TIN */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>TIN No</label>
                <input
                  type="text"
                  className="form-control"
                  name="tin_no"
                  value={inputs.tin_no || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Validity Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="tin_no_validaty_date"
                  value={inputs.tin_no_validaty_date || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
              <div className="col-lg">
                <label>Vat Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="tin_img"
                  onChange={handleFileChange3}
                  placeholder="Input license no"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg">
                <label>Present Address</label>
                <textarea
                  name="present_address"
                  className="form-control"
                  cols="30"
                  rows="5"
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-lg">
                <label>Permanent Address</label>
                <textarea
                  name="permanent_address"
                  className="form-control"
                  cols="30"
                  rows="5"
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  name="notes"
                  className="form-control"
                  cols="30"
                  rows="5"
                  onChange={handleChange}
                ></textarea>
              </div>
              {/* col */}
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Create
                </button>
              </div>
            </div>
            </> )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
